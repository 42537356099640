<template>
  <body>
    <div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebartype="full"
      data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
      <div class="dashboard-wrapper page-wrapper">
        <div class="page-breadcrumb">
          <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
              <h4 class="page-title"> Company Pay</h4>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-xlg-9 col-md-12">
              <ValidationObserver ref="simpleRules">
                <div class="card profile-card">
                  <div class="card-body">
                    <!-- <div class="form-group mb-4">
                      <label class="col-md-12 p-0">Company Name</label>
                      <div class="col-md-12 border-bottom p-0">
                        <validation-provider name="Company Name" rules="required|max:50" #default="{ errors }">
                          <b-input-group class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                            <b-input-group-prepend is-text>
                              <feather-icon icon="BriefcaseIcon" />
                            </b-input-group-prepend>
                            <b-form-input id="companyName" v-model="companies.name"
                              :state="errors.length > 0 ? false : null" placeholder="Company Name" />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div> -->

                    <div>
                      <form @submit.prevent="handlePayment">
                        <div class="form-group">
                          <label for="card-element">Card Details</label>
                          <div id="card-element" ref="cardElement"></div>
                          <div id="card-errors" class="text-danger">{{ errorMessage }}</div>
                        </div>
                        <button type="submit" class="btn btn-primary" :disabled="isProcessing">
                          {{ isProcessing ? 'Processing...' : `Pay ${companies.amount}` }}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>
<script>
import { loadStripe } from "@stripe/stripe-js";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import Vue from 'vue'
import { db, storage } from '@/main'
import firebase from 'firebase';
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
export default {
  name: 'Create',
  components: {
    ValidationProvider,
    ValidationObserver
  },
data() {
      this.pulishableKey = this.$pulishableKey;

  return {
    companies: null,  // Store company data
    stripe: null,
    cardElement: null,
    errorMessage: null,
  };
},
  mounted() {
      this.getCompany();
    this.initializeStripe();
  },
    created: function () {
    this.getCompany();
  },
  beforeDestroy() {
    if (this.cardElement) {
      this.cardElement.destroy(); // Clean up Stripe card element to avoid memory leaks
    }
  },
methods: {
  async getCompany() {
    try {
      const id = this.$route.params.id;  // Get the company ID from the route params
      const doc = await db.collection('companies').doc(id).get();  // Fetch company data from Firestore
      
      if (doc.exists) {
        this.companies = doc.data();  // Set the company data
        this.initializeStripe();  // Initialize Stripe after company data is fetched
      } else {
        throw new Error("Company not found");
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
      this.errorMessage = "Unable to load company data. Please try again later.";
    }
  },
async initializeStripe() {
  try {
    console.log("Fetched company data:", this.companies); // Log company data to see if it contains stripe_public_key
    if (this.companies && this.companies.stripe_public_key) {
      console.log("Initializing Stripe with key:", this.companies.stripe_public_key);
      this.stripe = Stripe(this.companies.stripe_public_key);  // Use the dynamic Stripe public key
    } else {
      throw new Error("Stripe public key is missing or invalid");
    }
    const elements = this.stripe.elements();
    this.cardElement = elements.create("card");
    console.log("Card element created:", this.cardElement);
    this.cardElement.mount(this.$refs.cardElement);
  } catch (error) {
    console.error("Stripe initialization error:", error);
    // this.errorMessage = "Unable to initialize payment1. Please try again later.";
  }
},
     // Handle payment submission
    async handlePayment() {
      this.isProcessing = true;
      this.isProcessing = true;
      this.errorMessage = "";
      try {
        // Create a Stripe token
        const { token, error } = await this.stripe.createToken(this.cardElement);
        if (error) {
          this.errorMessage = error.message;
          return;
        }
        // Send the token and amount to the backend
        await this.submitTokenToServer(token.id, this.companies.amount); // Pass amount here
      } catch (error) {
        console.error("Payment error:", error);
        this.errorMessage = "An unexpected error occurred. Please try again.";
      } finally {
        this.isProcessing = false;
      }
    },
    // Send payment token and amount to Laravel backend
async submitTokenToServer(token, amount) {
  try {
    const data = {
      token: token, // Pass the token ID (not the whole object)
      amount: amount,  // Amount in whole currency (backend multiplies by 100)
      description: "Active Intro",
      companyid: this.$route.params.id, // Pass the company ID
    };

    // const url = "http://localhost:8000/api/paymentcompanies";
     const url = this.$VUE_APP_API_ENDPOINT + "paymentcompanies";
    const response = await axios.post(url, data, {
      headers: { 'Content-Type': 'application/json' },
    });

    if (response.data.status === 'success') {
      alert("Payment successful!");
       db.collection("companies")
    .doc(this.$route.params.id) // Reference the correct document by ID
    .update({
     amount:0 // Use Firestore's server timestamp
    });
                 this.$router.push('/companiespay/index');

    } else {
      alert(response.data.message || "Payment failed. Please try again.");
              window.location.reload();   

    }
  } catch (error) {
    console.error("API error:", error);
    alert(error.response?.data?.message || "Payment failed. Please try again.");
       window.location.reload();   
  }
}



},
mounted() {
  this.getCompany();  // Call getCompany when the component is mounted
}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
<style scoped>
.main {
  padding: 45px 50px;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.map-holder {
  width: 65%;
}
#map {
  height: 70vh;
}
.dislpay-arena {
  background: #ffffff;
  box-shadow: 0px -3px 10px rgba(0, 58, 78, 0.1);
  border-radius: 5px;
  padding: 20px 30px;
  width: 25%;
}
.coordinates-header {
  margin-bottom: 50px;
}
.coordinates-header h3 {
  color: #1f2a53;
  font-weight: 600;
}
.coordinates-header p {
  color: rgba(13, 16, 27, 0.75);
  font-weight: 600;
  font-size: 0.875rem;
}
.form-group {
  position: relative;
}
#bb {
  background-color: #3cbba6;
  /* border: 2px solid #03e1bc; */
  color: #edf1f5;
  padding: 5px 6px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  /* margin: 10px 30px; */
  cursor: pointer;
  border-radius: 50px;
}

.location-control {
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(31, 42, 83, 0.25);
  box-shadow: 0px 0px 10px rgba(73, 165, 198, 0.1);
  border-radius: 4px;
  padding: 0px 10px;
  width: 90%;
}

.location-control:focus {
  outline: none;
}

.location-btn {
  margin-top: 15px;
  padding: 10px 15px;
  background: #d80739;
  box-shadow: 0px 4px 10px rgba(73, 165, 198, 0.1);
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 600;
}

.location-btn:focus {
  outline: none;
}

.disabled {
  background: #db7990;
  cursor: not-allowed;
}

.copy-btn {
  background: #f4f6f8 0% 0% no-repeat padding-box;
  border: 1px solid #f4f6f8;
  border-radius: 0px 3px 3px 0px;
  position: absolute;
  color: #5171ef;
  font-size: 0.875rem;
  font-weight: 500;
  height: 30px;
  padding: 0px 10px;
  cursor: pointer;
  right: 3.5%;
  top: 5%;
}

.copy-btn:focus {
  outline: none;
}

.dislpay-arena[data-v-0d798840] {
  width: 30%;
}

.coordinates-header h3[data-v-0d798840] {
  font-size: 20px;
}
</style>
